import { useCallback, useEffect, useState } from 'react'

import pole from '@eaze/pole'
import Visibility from 'document-visibility'
import window from 'global/window'
import { useDispatch } from 'react-redux'

import { setVersionMismatch } from 'redux/app/actions'
import { rehydrateCookies } from 'redux/cookies/actions'

const visibility = Visibility()
const POLL_FREQUENCY = 90000 * 1000

interface FetchVersionCallback {
  (err: Error | null, res?: Response): void
}

/**
 * Custom hook to manage page logic, including version polling and visibility handling.
 *
 * @remarks
 * This hook should only be used at the top level of a page component in `/pages/page-name.tsx`.
 * It handles:
 * - Fetching and comparing the app version from the server
 * - Polling for version updates
 * - Managing visibility changes
 * - Rehydrating cookies
 * - Deregistering service workers
 *
 * @returns This hook does not return any value.
 */

export default function usePageLogic(): null {
  const dispatch = useDispatch()
  const [version, setVersion] = useState<string | null>(null)
  const [versionPoll, setVersionPoll] = useState<ReturnType<typeof pole> | null>(null)
  const [visible, setVisible] = useState<boolean>(visibility.visible)

  useEffect(() => {
    const handleVisibilityChange = (visible: boolean) => setVisible(visible)
    const unlistenVisibility: () => void = visibility.onChange(handleVisibilityChange)
    return () => unlistenVisibility()
  }, [])

  const fetchVersion = useCallback(async (callback: FetchVersionCallback) => {
    // Ensure this runs only on the client side
    if (!window.location || !window.location.origin) return callback(null)

    // let's hit out root/version and see what version we're on
    try {
      const res = await fetch(`${window.location.origin}/status`)
      const body = res.headers.get('content-type')?.includes('json') ? await res.json() : await res.text()
      const serverVersion = body.version

      if (!res.ok) {
        console.info(`FetchVersion request status ${res.status} not OK`, body)
        throw new Error(`FetchVersion request status ${res.status} not OK`)
      }
      //  we don't have a version yet? let's save the commit hash in state.
      if (version === null && serverVersion) {
        setVersion(serverVersion)
      } else if (version !== null && version !== serverVersion) {
        // we have state.version and serverVersion isn't equal to it.. let's refresh the app
        // to ensure the users are on the latest version
        dispatch(setVersionMismatch())
      }

      return callback(null, res)
    } catch (err) {
      return callback(err, null)
    }
  }, [version, dispatch])

  const startPolling = useCallback(() => {
    if (!versionPoll) {
      const poll = pole({ interval: POLL_FREQUENCY }, (callback) => fetchVersion(callback))
      setVersionPoll(poll)
    }
  }, [fetchVersion, versionPoll])

  const cancelPolling = useCallback(() => {
    if (versionPoll) {
      versionPoll.cancel()
      setVersionPoll(null)
    }
  }, [versionPoll])

  useEffect(() => {
    rehydrateCookies(dispatch)

    if (visible) {
      startPolling()
    } else {
      cancelPolling()
    }

    deregisterServiceWorker()

    return () => {
      cancelPolling()
    }
  }, [startPolling, cancelPolling, visible, dispatch])

  async function deregisterServiceWorker(): Promise<void> {
    if (window.navigator && navigator.serviceWorker) {
      const registrations = await navigator.serviceWorker.getRegistrations()
      for (const registration of registrations) {
        registration.unregister()
      }
    }
  }

  return null
}
